<template>
  <div>
    <v-card rounded="lg" class="mb-5 hidden-md-and-down">
      <v-carousel cycle hide-delimiters>
        <v-carousel-item v-for="(item, i) in baners" :key="i" :src="item.src">
        </v-carousel-item>
      </v-carousel>
    </v-card>

    <v-card rounded="lg" class="mb-5 hidden-lg-and-up">
      <v-carousel cycle hide-delimiters height="auto">
        <v-carousel-item v-for="(item, i) in baners" :key="i" :src="item.src">
        </v-carousel-item>
      </v-carousel>
    </v-card>

    <div class="mb-5">
      <v-card class="px-5 pt-5 text-center" flat>
        <h2 class="mb-5 primary--text">SAMBUTAN INSPEKTUR</h2>
        <v-col cols="12" class="outlined text-center">
          <v-avatar size="170">
            <v-img :src="getIMG(item.profil_file)"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="12" class="primary--text" v-html="item.profil_konten">
          {{ item.profil_konten }}
        </v-col>
      </v-card>
    </div>

    <div>
      <v-row>
        <v-col cols="12" md="8">
          <v-divider></v-divider>
          <div class="py-3">
            <h2 class="primary--text">BERITA TERBARU</h2>
          </div>
          <v-divider class="mb-5"></v-divider>

          <div v-if="beritaHot.length === 0">
            <v-alert type="error">
              Belum Ada <strong>Berita</strong> Untuk Saat Ini
            </v-alert>
          </div>

          <v-card
            v-else
            class="px-5 py-2 mb-5"
            cols="12"
            v-for="item in beritaHot"
            :key="item.berita_id"
            :to="'/informasi/berita/' + item.berita_slug"
          >
            <v-row>
              <v-col cols="12" md="4" class="my-auto">
                <v-img :src="beritaIMG(item.berita_thumbnail)"></v-img>
              </v-col>

              <v-col cols="12" md="8" class="mb-3">
                <div>
                  <h2>{{ item.berita_judul }}</h2>
                </div>

                <v-divider class="my-3"></v-divider>

                <div>
                  <span v-html="item.berita_isi.slice(0, 350) + '...'"></span>
                </div>

                <v-divider class="my-3"></v-divider>

                <div>
                  <v-icon small left>mdi-clock-outline</v-icon>
                  <span class="text--disabled">
                    {{ tglFormat(item.berita_created_at) }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-divider></v-divider>
          <div class="py-3">
            <h2 class="primary--text">PENGUMUMAN</h2>
          </div>
          <v-divider class="mb-5"></v-divider>

          <div v-if="pengumumanHot.length === 0">
            <v-alert type="error">
              Belum Ada <strong>Pengumuman</strong> Untuk Saat Ini
            </v-alert>
          </div>

          <div v-for="item in pengumumanHot" :key="item.pengumuman_id">
            <v-card
              v-if="item.pengumuman_jenis === 'penting'"
              class="mb-5 white--text"
              color="error"
              flat
            >
              <v-card-title class="caption">
                <div>
                  <v-icon small left color="white">mdi-clock-outline</v-icon>
                  <span class="white--text">
                    {{ tglFormat(item.pengumuman_created_at) }}
                  </span>
                </div>

                <v-spacer></v-spacer>
              </v-card-title>

              <v-divider class="mx-3 mt-n3 mb-3"></v-divider>

              <div class="px-3">
                <h2>{{ item.pengumuman_judul }}</h2>
              </div>

              <v-card-text class="white--text">
                <span v-html="item.pengumuman_isi"></span>
              </v-card-text>
            </v-card>

            <v-card v-else class="mb-5" flat>
              <v-card-title class="caption">
                <div>
                  <v-icon small left>mdi-clock-outline</v-icon>
                  <span class="text--disabled">
                    {{ tglFormat(item.pengumuman_created_at) }}
                  </span>
                </div>

                <v-spacer></v-spacer>
              </v-card-title>

              <v-divider class="mx-3 mt-n3 mb-3"></v-divider>

              <div class="px-3">
                <h2>{{ item.pengumuman_judul }}</h2>
              </div>

              <v-card-text>
                <span v-html="item.pengumuman_isi"></span>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    session: "",

    item: {},
    beritaHot: [],
    pengumumanHot: [],

    baners: [
      {
        src: require("../../assets/gambar/baner1.jpg"),
      },
      {
        src: require("../../assets/gambar/baner2.jpg"),
      },
    ],
  }),

  async mounted() {
    this.getData();
    this.getHot();
    this.getPengumuman();
  },

  methods: {
    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/profilGambar/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "images/avatar-default.png";
      }
    },

    beritaIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/beritaGambar/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "upload/beritaGambar/default.jpg";
      }
    },

    tglFormat(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },

    getHot() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "berita/hot")
        .then((res) => {
          this.beritaHot = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPengumuman() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "pengumuman/hot")
        .then((res) => {
          this.pengumumanHot = res.data.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "profil/sambutan")
        .then((res) => {
          this.item = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
